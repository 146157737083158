<template lang='pug'>
	v-app(class='exception')
		v-container(fluid fill-height)
			v-layout(align-center justify-center)
				div(class='text-md-center')
					h1 404
					h2(class='my-3 headline') Sorry, page not found
					div
						router-link(to='/dashboard', style='text-decoration: none; color: white')
							v-btn(color='primary') Go Home
</template>

<script>
export default {
	methods: {
		goHome() {
			this.$router.push({ path: '/' });
		}
		
	},
	created() {
		this.$vuetify.theme.dark = false;
	}
};
</script>
<style scoped lang='css'>
h1 {
  font-size: 150px;
  line-height: 150px;
  font-weight: 700;
  color: #252932;
  text-shadow: rgba(61, 61, 61, 0.3) 1px 1px, rgba(61, 61, 61, 0.2) 2px 2px, rgba(61, 61, 61, 0.3) 3px 3px;
}
</style>
